<script setup lang="ts">
import BtnPrimary from '@/components/ui/BtnPrimary.vue';
import { BenefitIds } from '@/types/enums';

const emit = defineEmits<{
  (e: 'learn-more', benefitId: BenefitIds): void;
}>();

const props = defineProps<{
  name: string;
  title: string;
  description: string;
  textColor: string;
  isLast: boolean;
  cardBg: string;
  benefitId: BenefitIds;
  className: string;
}>();

const isHovered = ref(false);

const handleLearnMore = () => {
  emit('learn-more', props.benefitId);
};
</script>

<template>
  <div
    class="benefit-card text-white w-100 d-flex flex-column justify-space-between"
    :class="className"
    :style="{
      borderRight: isLast ? 'none' : '1px solid rgb(var(--v-theme-secondary))',
    }"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div class="z-index-10">
      <span class="card-name" :class="textColor">{{ name }}</span>
      <slot />
      <span class="card-description">{{ description }}</span>
    </div>

    <transition name="fade">
      <div
        v-if="isHovered"
        class="hover-block z-index-0"
        :style="{
          backgroundImage: `url(${cardBg})`,
          backgroundSize: 'cover',
        }"
      ></div>
    </transition>

    <!-- <div>
      <BtnPrimary title="Learn more" @callback="handleLearnMore" />
    </div> -->

    <div class="desktop-buttons">
      <div v-show="isHovered">
        <BtnPrimary title="Learn more" @callback="handleLearnMore" />
      </div>
      <div v-show="!isHovered">
        <BtnPrimary
          title="Learn more"
          variant="outlined"
          @callback="handleLearnMore"
        />
      </div>
    </div>

    <div class="mobile-buttons">
      <BtnPrimary
        title="Learn more"
        variant="outlined"
        @callback="handleLearnMore"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.benefit-card {
  background: rgb(var(--v-theme-primary));
  border-top: 1px solid rgb(var(--v-theme-secondary));
  padding: 40px;
  min-height: 740px;
  position: relative;
  transition: all 0.3s ease;
}

.card-name {
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
}

.card-description {
  font-size: 14px;
  font-weight: 320;
}

.hover-block {
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  @media screen and (max-width: 576px) {
    display: none !important;
  }
}

.benefit-card:hover .hover-block {
  opacity: 1;
}

.z-index-10 {
  z-index: 10 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

:deep(.v-btn) {
  margin-top: 22px;
  font-size: 15px !important;
  padding: 16px 33px !important;
  border: thin solid currentColor;
}

:deep(.v-btn__content) {
  font-size: 15px !important;
  line-height: 22.5px !important;
  font-weight: 500 !important;
  letter-spacing: normal !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.mobile-buttons {
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .benefit-card {
    padding: 70px 201px 70px;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .benefit-card {
    padding: 70px 100px 70px;
    border-right: none !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 1024px) {
  .card-description {
    font-size: 15px;
    font-weight: 320;
    line-height: 24px;
    text-align: left;
  }

  .desktop-buttons {
    display: none;
  }

  .mobile-buttons {
    display: block;
  }

  :deep(.v-btn) {
    padding: 16px 66px !important;
  }
}

@media screen and (max-width: 576px) {
  .benefit-card {
    padding: 40px 20px;
  }

  .desktop-buttons {
    display: none;
  }

  .mobile-buttons {
    display: block;
  }
}
</style>
