<script setup lang="ts">
import TrustedPartner1Icon from '@/assets/images/svg/trusted-partners/trusted-partner-1.svg';
import TrustedPartner2Icon from '@/assets/images/svg/trusted-partners/trusted-partner-2.svg';
import TrustedPartner3Icon from '@/assets/images/svg/trusted-partners/trusted-partner-3.svg';
import TrustedPartner4Icon from '@/assets/images/svg/trusted-partners/trusted-partner-4.svg';
import TrustedPartner5Icon from '@/assets/images/svg/trusted-partners/trusted-partner-5.svg';
import TrustedPartner6Icon from '@/assets/images/svg/trusted-partners/trusted-partner-6.svg';
import TrustedPartner7Icon from '@/assets/images/svg/trusted-partners/trusted-partner-7.svg';
import TrustedPartner8Icon from '@/assets/images/svg/trusted-partners/trusted-partner-8.svg';

const props = defineProps<{
  height?: number;
  classNames?: string;
}>();

const partners = [
  TrustedPartner1Icon,
  TrustedPartner2Icon,
  TrustedPartner3Icon,
  TrustedPartner4Icon,
  TrustedPartner5Icon,
  TrustedPartner6Icon,
  TrustedPartner7Icon,
  TrustedPartner8Icon,
];
</script>

<template>
  <div
    class="running-line w-100 position-relative"
    :class="[props.classNames]"
    :style="{ height: `${props.height}px` }"
  >
    <Vue3Marquee :duration="30" :pause-on-hover="false">
      <div
        v-for="(partnerIcon, index) in partners"
        :key="index"
        class="icon-running-line-wrapper"
      >
        <component :is="partnerIcon" class="trusted-partner-icon" />
      </div>
    </Vue3Marquee>
  </div>
</template>

<style scoped lang="scss">
.running-line {
  overflow: hidden;
  min-height: 51px;
  position: relative;
  display: flex;
  align-items: center;
}

.icon-running-line-wrapper {
  margin-right: 70px;
}

.trusted-partner-icon {
  opacity: 0.6;
}
</style>
