<script setup lang="ts">
import { ref, watch } from 'vue';
import IconPlus from '@/assets/images/svg/icons/ic_plus.svg';
import IconMinus from '@/assets/images/svg/icons/ic_minus.svg';
import BtnPrimary from '@/components/ui/BtnPrimary.vue';
import { SectionIds } from '@/types/enums';

const sectionId = ref(SectionIds.FAQ);
const config = useRuntimeConfig();
const commonQuestions = [
  {
    id: 1,
    question:
      'What does the accelerator program offer, and how can it help my startup grow?',
    answer: '',
  },
  {
    id: 2,
    question: 'What is the timeline for the next upcoming batch?',
    answer:
      'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
  },
  {
    id: 3,
    question: 'Is there funding available, and how does it work?',
    answer:
      'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
  },
  {
    id: 4,
    question:
      'Can international startups apply, or is it only for Austrian companies?',
    answer:
      'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
  },
  {
    id: 5,
    question: 'How many startups are accepted into each batch?',
    answer:
      'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
  },
  {
    id: 6,
    question: 'Can I participate in the accelerator if I’m a solo founder?',
    answer:
      'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
  },
  {
    id: 7,
    question:
      'What are the selection criteria & process for startups applying to the accelerator?',
    answer:
      'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
  },
  {
    id: 8,
    question: 'What is the time commitment for the program?',
    answer:
      'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
  },
  {
    id: 9,
    question: 'What does it cost to participate in the accelerator?',
    answer:
      'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
  },
  {
    id: 10,
    question: 'Will I have to relocate to participate in the accelerator?',
    answer:
      'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
  },
  {
    id: 11,
    question: 'Can I participate in the accelerator if I’m a solo founder?',
    answer:
      'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
  },
  {
    id: 12,
    question:
      'How is the mentoring program structured, and what can I expect from it?',
    answer:
      'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
  },
];

onMounted(() => {
  const script = document.createElement('script');
  script.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
  document.body.appendChild(script);

  const jQueryScript = document.createElement('script');
  jQueryScript.innerHTML = `
    (function($) {
      window.fnames = new Array(); 
      window.ftypes = new Array();
      fnames[0]='EMAIL'; ftypes[0]='email';
      fnames[1]='FNAME'; ftypes[1]='text';
      fnames[5]='ANREDE'; ftypes[5]='dropdown';
      fnames[2]='LNAME'; ftypes[2]='text';
      fnames[4]='COMPANY'; ftypes[4]='text';
      fnames[6]='MMERGE6'; ftypes[6]='text';
    }(jQuery));
    var $mcj = jQuery.noConflict(true);
  `;
  document.body.appendChild(jQueryScript);
});

const handleApply = () => {
  // const link = config.public.membershipLink;
  const link =
    'https://wexelerate-membership.notion.site/1308e130c0a980a08eecc8c81833109c';

  window.open(link, '_blank');
};

const handleSignUpUpdates = () => {
  navigateTo('/sign-up');
};

const handleScrollToQuestion = async (id: string) => {
  // await navigateTo('/');
  // nextTick(async () => {
  //   const element = document.getElementById(`${id}`);
  //   if (element) {
  //     setTimeout(() => {
  //       const elementRect = element.getBoundingClientRect();
  //       const offset = window.scrollY + elementRect.top;
  //       const headerHeight = 100;
  //       const duration = 1200;
  //       const start = window.scrollY;
  //       const change = offset - headerHeight - start;
  //       const increment = 20;
  //       const animateScroll = (elapsedTime: number) => {
  //         elapsedTime += increment;
  //         const position = easeInOutQuad(elapsedTime, start, change, duration);
  //         window.scrollTo(0, position);
  //         if (elapsedTime < duration) {
  //           setTimeout(() => animateScroll(elapsedTime), increment);
  //         }
  //       };
  //       const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
  //         t /= d / 2;
  //         if (t < 1) return (c / 2) * t * t + b;
  //         t--;
  //         return (-c / 2) * (t * (t - 2) - 1) + b;
  //       };
  //       animateScroll(0);
  //     }, 500);
  //   } else {
  //     console.warn(`Element with id ${id} not found.`);
  //   }
  // });
};
</script>

<template>
  <div class="faq-section-wrapper">
    <section class="faq-section" :id="sectionId">
      <div class="faq-section-title section-name mb-10 text-left">FAQ</div>
      <div>
        <div class="title text-left">Decision Time.</div>
        <div
          class="title-btn-wrapper d-flex align-center justify-space-between"
        >
          <span class="title text-lime-green text-left">It’s Your Turn!</span>
          <BtnPrimary
            title="Apply now!"
            variant="outlined"
            @callback="handleApply"
          />
        </div>
      </div>

      <div class="questions">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-title
              :collapse-icon="IconMinus"
              :expand-icon="IconPlus"
              id="question-1"
              @click="handleScrollToQuestion('question-1')"
            >
              What does the accelerator program offer, and how can it help my
              startup grow?
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Our accelerator program is designed with six key components to
              drive your startup’s growth:

              <ul class="faq-list">
                <li class="faq-list-item">
                  <b class="text-bold">Ecosystem Access:</b> Gain direct access
                  to our network of corporates to help secure new customers and
                  initiate pilot projects. This access accelerates your market
                  entry and builds credibility through real-world
                  collaborations.
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">Mentoring:</b> Receive personalized
                  support from top mentors, offering guidance, sparring, and
                  topic-specific expertise for your professional development.
                  This gives you easy access to insights and networks that are
                  vital for your growth.
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">Co-Working:</b> Enjoy two flex desks, an
                  official business address, and more at our central Vienna
                  location. This provides you with an inspiring and professional
                  workspace, fueling productivity and networking opportunities.
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">Marketing for Your Startup:</b> We
                  promote your startup through our Venture Cake and
                  <a
                    class="text-soft-teal"
                    href="http://wexelerate.com/"
                    target="_blank"
                    >weXelerate</a
                  >
                  communication channels. This boosts your visibility, helping
                  your vision reach a larger audience and gain valuable support.
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">Perks:</b> As a participant, you’re
                  eligible to join the Google Cloud Startup Program, with access
                  to up to $250,000 in free cloud credits if desired. This gives
                  you resources to scale your tech infrastructure without the
                  upfront costs.
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">Selective Funding Support:</b> While we
                  don’t guarantee grants or investments, we prepare you to be
                  investor-ready. From low-barrier access to investors to
                  comprehensive sparring sessions, we offer full support to help
                  you secure funding for the next phase. This ensures that
                  you’re well-prepared to attract investment and fuel future
                  growth.
                  <p>
                    Additionally, the top-performing startup in the batch,
                    crowned on Demo Day, will receive a
                    <b class="text-bold">€25,000 cash prize</b>—no strings
                    attached, no additional equity required. Think of it as the
                    <b class="text-bold">sweetest slice of the cake</b>,
                    rewarding excellence and celebrating your hard work.
                  </p>
                </li>
              </ul>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              :collapse-icon="IconMinus"
              :expand-icon="IconPlus"
              id="question-2"
              @click="handleScrollToQuestion('question-2')"
            >
              Do I have to give up equity to participate in the program?
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              If you qualify for Venture Cake, you gain access to an extensive
              and valuable support program, both during the program and as an
              alumni. To participate, we take a 4% equity share. Additionally,
              as a founder, you receive 4% of the entire "batch cake" in return.
              This unique structure—Add a Layer, Take a Slice—allows you to
              benefit from the success of other startups in the batch, fostering
              a strong, collaborative community.
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              :collapse-icon="IconMinus"
              :expand-icon="IconPlus"
              id="question-3"
              @click="handleScrollToQuestion('question-3')"
            >
              What are the selection criteria & process for startups applying to
              the accelerator?
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              We look for startups that meet the following criteria to maximize
              their chances of acceptance, but we recognize that every startup
              is unique—don’t hesitate to apply!

              <ul class="faq-list">
                <li class="faq-list-item">
                  <b class="text-bold"
                    >Industry-Agnostic with European Ambitions:</b
                  >
                  Startups from any industry are welcome, as long as they have
                  plans to expand across Europe or Worldwide.
                </li>
                <li class="faq-list-item">
                  <b class="text-bold"
                    >Incorporated or Planning to Enter Austria:</b
                  >
                  Preference for startups already incorporated in Austria or
                  looking to enter the Austrian market, particularly from the
                  CEE region.
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">Full-Time Dedication:</b> The founder
                  should be fully committed and working on the startup
                  full-time.
                </li>
                <li class="faq-list-item">
                  <b class="text-bold"
                    >MVP/POC Stage with Customer Validation:</b
                  >
                  Startups should be at least at the MVP/POC stage, ideally with
                  one reference customer (paying or not).
                </li>
                <li class="faq-list-item">
                  No Significant Investment Yet: Startups without significant
                  outside investment, although funding from friends and family
                  is okay.
                </li>
              </ul>
              We consider these factors in our selection process, but we’re open
              to diverse applications. If you’re passionate and meet some of
              these criteria, we encourage you to apply!
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              :collapse-icon="IconMinus"
              :expand-icon="IconPlus"
              id="question-4"
              @click="handleScrollToQuestion('question-4')"
            >
              What is the timeline for the next upcoming batch?
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <p>
                Please check
                <a
                  class="text-soft-teal"
                  href="https://www.eventbrite.com/cc/venturecake-accelerator-1722019"
                  target="_blank"
                  >venturecake event on eventbrite</a
                >
                for up2date information
              </p>
              <ul class="faq-list">
                <li class="faq-list-item">
                  <b class="text-bold">20.11.24, 12.12.24 and 07.01.25</b> -
                  Info Session for Founders (Register
                  <a
                    class="text-soft-teal"
                    href="https://www.eventbrite.com/cc/venturecake-accelerator-1722019"
                    target="_blank"
                    >here</a
                  >)
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">12.01.25</b> – Application Deadline
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">February 2025</b> - Startups will be
                  informed about decission
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">05.03.</b> – Kick-off Event in Vienna
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">03.03. - 21.03.</b> – On-Site Accelerator
                  Days - Part 1
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">28.04. - 16.05.</b> – On-Site Accelerator
                  Days - Part 2
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">25.08. - 05.09.</b> – On-Site Accelerator
                  Days - Part 3
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">September 2025</b> – Demo Day in Vienna
                </li>
              </ul>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              :collapse-icon="IconMinus"
              :expand-icon="IconPlus"
              id="question-5"
              @click="handleScrollToQuestion('question-5')"
            >
              What is the time commitment for the program?
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              To make the most of this accelerator, we ask for a strong
              commitment from participating startups. All stakeholders are
              working towards accelerating your success, and the same dedication
              is expected from you.
              <ul class="faq-list">
                <li class="faq-list-item">
                  <b class="text-bold">Mandatory In-Person Participation:</b>
                  Attendance at key events, including the Kick-off and Demo Day,
                  as well as all scheduled On-Site Accelerator Days, is
                  essential.
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">Availability for Sessions:</b> You should
                  be reachable between these events for mentor sessions, partner
                  meetings, and other important engagements.
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">Networking and Community Engagement:</b>
                  Active participation in networking events and community
                  activities to strengthen relationships within the ecosystem
                  and gain valuable insights from other founders.
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">Transparency and Communication:</b> We
                  require transparency regarding key developments and
                  challenges. Important events or information that may affect
                  your progress or the valuation of Venture Cake shares should
                  be communicated openly, so we can provide timely support.
                </li>
              </ul>
              Failure to meet these commitments may result in removal from the
              program, forfeiting eligibility for Venture Cake equity shares. -
              We do not offer guaranteed funding as part of the program.
              However, we collaborate with leading funding agencies and
              investors within Austria's ecosystem. This provides you with
              direct access and attractive opportunities to secure financing.
              While we can't promise funding, our clear goal is to do everything
              possible to help you secure the necessary investment together.
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              :collapse-icon="IconMinus"
              :expand-icon="IconPlus"
              id="question-6"
              @click="handleScrollToQuestion('question-6')"
            >
              Is there funding available, and how does it work?
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <p>
                We do not offer guaranteed funding as part of the program.
                However, we collaborate with leading funding agencies and
                investors within Austria's ecosystem. This provides you with
                direct access and attractive opportunities to secure financing.
                While we can't promise funding, our clear goal is to do
                everything possible to help you secure the necessary investment
                together.
              </p>
              <p class="mt-2">
                Additionally, the top-performing startup in the batch, crowned
                on Demo Day, will receive a
                <b class="text-bold">€25,000 cash prize</b>—no strings attached,
                no additional equity required. Think of it as the
                <b class="text-bold">sweetest slice of the cake</b>, rewarding
                excellence and celebrating your hard work.
              </p>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              :collapse-icon="IconMinus"
              :expand-icon="IconPlus"
              id="question-7"
              @click="handleScrollToQuestion('question-7')"
            >
              What does it cost to participate in the accelerator?
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <p>
                Participation in our accelerator program requires an equity
                contribution of 4%. In return, you’ll receive a 4% share in the
                collective "batch cake," allowing you to benefit from the
                successes of other startups in the program.
              </p>
              <p>Beyond this equity exchange, there are no additional fees.</p>
              <p>
                However, for international startups, travel and accommodation
                costs during on-site sessions are not covered and may need to be
                arranged independently.
              </p>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              :collapse-icon="IconMinus"
              :expand-icon="IconPlus"
              id="question-8"
              @click="handleScrollToQuestion('question-8')"
            >
              Can international startups apply, or is it only for Austrian
              companies?
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <p>
                Yes, international startups are welcome to apply! However, a
                requirement for participation is the intention to establish a
                company in Vienna.
              </p>
              <p>
                We especially encourage applications from startups in Central
                and Eastern Europe (CEE), as they are a key focus of our
                accelerator program. - No, relocation is not required. However,
                reliable on-site attendance is expected during certain
                accelerator phases (see the timeline in our FAQ). These
                in-person sessions are essential for maximizing the benefits of
                the program.
              </p>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              :collapse-icon="IconMinus"
              :expand-icon="IconPlus"
              id="question-9"
              @click="handleScrollToQuestion('question-9')"
            >
              Will I have to relocate to participate in the accelerator?
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              No, relocation is not required. However, reliable on-site
              attendance is expected during certain accelerator phases (see the
              timeline in our FAQ). These in-person sessions are essential for
              maximizing the benefits of the program. - Our goal is to accept up
              to 15 startups per batch, creating an ideal group size for
              collaborative growth and development. However, quality and fit
              with the program are our top priorities. If necessary, we may
              start a batch with fewer startups to ensure alignment with our
              standards.
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              :collapse-icon="IconMinus"
              :expand-icon="IconPlus"
              id="question-10"
              @click="handleScrollToQuestion('question-10')"
            >
              How many startups are accepted into each batch?
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Our goal is to accept up to 15 startups per batch, creating an
              ideal group size for collaborative growth and development.
              However, quality and fit with the program are our top priorities.
              If necessary, we may start a batch with fewer startups to ensure
              alignment with our standards.
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              :collapse-icon="IconMinus"
              :expand-icon="IconPlus"
              id="question-11"
              @click="handleScrollToQuestion('question-11')"
            >
              Can I participate in the accelerator if I’m a solo founder?
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Yes, solo founders are welcome to apply, as long as you’re working
              on your startup full-time. Our ecosystem also provides great
              opportunities to connect with others, potentially helping you find
              a compatible co-founder if needed.
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              :collapse-icon="IconMinus"
              :expand-icon="IconPlus"
              id="question-12"
              @click="handleScrollToQuestion('question-12')"
            >
              Is there a mandatory requirement to be on-site during the program?
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Yes, there are three phases within the program that require
              on-site presence:
              <ul class="faq-list">
                <li class="faq-list-item">
                  <b class="text-bold">03.03. - 21.03.</b> – On-Site Accelerator
                  Days - Part 1
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">28.04. - 16.05.</b> – On-Site Accelerator
                  Days - Part 2
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">25.08. - 05.09.</b> – On-Site Accelerator
                  Days - Part 3
                </li>
              </ul>
              These in-person sessions are essential for maximizing
              collaboration and program benefits.
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              :collapse-icon="IconMinus"
              :expand-icon="IconPlus"
              id="question-13"
              @click="handleScrollToQuestion('question-13')"
            >
              How is the mentoring program structured, and what can I expect
              from it?
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              Our mentoring program consists of two types of mentors:
              <ul class="faq-list">
                <li class="faq-list-item">
                  <b class="text-bold">Topic Mentors:</b> Experts in specific
                  fields who lead knowledge sessions tailored to the needs of
                  each batch. These sessions cover critical areas for startup
                  success, including:
                  <ul class="faq-list">
                    <li class="faq-list-item">
                      <b class="text-bold">Funding:</b> Capital raising, funding
                      round structuring, and scaling operations.
                    </li>
                    <li class="faq-list-item">
                      <b class="text-bold">Product Development & Technology:</b>
                      Building scalable products and driving digital
                      transformation.
                    </li>
                    <li class="faq-list-item">
                      <b class="text-bold">HR, Operations & Scaling:</b>
                      Managing and growing your team effectively.
                    </li>
                    <li class="faq-list-item">
                      <b class="text-bold"
                        >Personal Development & Resilience:</b
                      >
                      Skills in pitching, presentation, and resilience.
                    </li>
                    <li class="faq-list-item">
                      <b class="text-bold">Financial Management:</b> Cash flow
                      control and financial sustainability.
                    </li>
                    <li class="faq-list-item">
                      <b class="text-bold">Legal & IP Handling:</b> Contract, IP
                      protection, and legal support.
                    </li>
                    <li class="faq-list-item">
                      <b class="text-bold">Industry & Corporate Competence:</b>
                      Insights into corporate collaboration and innovation
                      partnerships.
                    </li>
                  </ul>
                </li>
                <li class="faq-list-item">
                  <b class="text-bold">Success Mentors:</b> Assigned to you as
                  dedicated mentors, Success Mentors support your development
                  throughout the program. They provide one-on-one sparring and
                  guidance, helping you navigate the ecosystem and make the most
                  of the program’s resources.
                </li>
              </ul>
              This structure ensures you receive specialized guidance in key
              areas while having a dedicated mentor committed to your growth and
              success.
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title
              :collapse-icon="IconMinus"
              :expand-icon="IconPlus"
              id="question-14"
              @click="handleScrollToQuestion('question-14')"
            >
              Are you looking for mentors as well?
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <p>
                Yes, we are always on the lookout for competent and passionate
                mentors to support our startups. Mentoring isn’t a job—it’s a
                passion. It’s about giving back and empowering the next
                generation of entrepreneurs. While there’s no monetary
                compensation, mentoring offers numerous incentives, such as
                access to top talent, networking opportunities, and increased
                visibility through our marketing initiatives.
              </p>
              <p>
                If this resonates with you and you’re interested in joining us
                as a mentor, feel free to apply through our
                <a
                  class="text-soft-teal"
                  href="https://www.notion.so/1308e130c0a980f5b130c76a5b4e0aa9?pvs=4"
                  >mentor application form</a
                >. We’d love to hear from you!
              </p>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <section class="not-sure-section">
        <div class="faq-section-title text-primary-purple section-name mb-10">
          Decision Time
        </div>
        <div class="not-sure-wrapper d-flex justify-space-between align-end">
          <div class="not-sure-title-desktop">
            <div class="title-not-sure text-lime-green">not</div>
            <span class="title-not-sure text-lime-green">sure yet?</span>
          </div>

          <div class="not-sure-title-mobile">
            <div class="title-not-sure text-lime-green">not sure</div>
            <span class="title-not-sure text-lime-green">yet?</span>
          </div>
          <div
            class="not-sure-sign-up-wrapper d-flex align-end justify-space-between"
          >
            <div
              class="not-sure-sign-up-content d-flex flex-column align-center"
            >
              <span
                class="sign-up-text w-100 d-flex flex-column align-end mb-8"
              >
                <span class="text-right">
                  Don’t miss application deadline
                </span>
                <span class="text-right"> and stay up to date</span>
              </span>
              <BtnPrimary
                :classNames="['btn-sign-up']"
                title="Sign up for updates"
                @callback="handleSignUpUpdates"
              />

              <!-- <v-container>
                <v-dialog max-width="500">
                  <template v-slot:activator="{ props: activatorProps }">
                    <BtnPrimary
                      :activator-props="activatorProps"
                      :classNames="['btn-sign-up']"
                      title="Sign up for updates"
                    />
                  </template>

                  <template v-slot:default="{ isActive }">
                    <v-card>
                      <v-card-text>
                        <div id="mc_embed_shell" class="sign-up-form">
                          <link
                            href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
                            rel="stylesheet"
                            type="text/css"
                          />
                          <div id="mc_embed_signup">
                            <form
                              action="https://wexelerate.us15.list-manage.com/subscribe/post?u=8a6ba8cad090d6bca19b54352&amp;id=fbaee145a1&amp;v_id=3039&amp;f_id=00e3a6e0f0"
                              method="post"
                              id="mc-embedded-subscribe-form"
                              name="mc-embedded-subscribe-form"
                              class="validate"
                              target="_blank"
                            >
                              <div id="mc_embed_signup_scroll">
                                <h2>Subscribe</h2>
                                <div class="indicates-required">
                                  <span class="asterisk">*</span> indicates
                                  required
                                </div>
                                <div class="mc-field-group">
                                  <label for="mce-EMAIL"
                                    >Email Address
                                    <span class="asterisk">*</span></label
                                  >
                                  <input
                                    type="email"
                                    name="EMAIL"
                                    class="required email"
                                    id="mce-EMAIL"
                                    required
                                    value=""
                                  />
                                </div>
                                <div class="mc-field-group">
                                  <label for="mce-FNAME">First Name </label>
                                  <input
                                    type="text"
                                    name="FNAME"
                                    class="text"
                                    id="mce-FNAME"
                                    value=""
                                  />
                                </div>
                                <div
                                  id="mergeRow-gdpr"
                                  class="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group"
                                >
                                  <div class="content__gdpr">
                                    <label class="mb-2">GDPR</label>
                                    <fieldset
                                      class="mc_fieldset gdprRequired mc-field-group"
                                      name="interestgroup_field"
                                    >
                                      <label
                                        class="checkbox subfield"
                                        for="gdpr19"
                                      >
                                        <input
                                          type="checkbox"
                                          id="gdpr_19"
                                          name="gdpr[19]"
                                          class="gdpr"
                                          value="Y"
                                        />
                                        <span>I agree to receive updates</span>
                                      </label>
                                    </fieldset>
                                    <p>Your trust is our priority...</p>
                                  </div>
                                  <div class="content__gdprLegal">
                                    <p>
                                      We use Mailchimp as our marketing
                                      platform...
                                    </p>
                                  </div>
                                </div>
                                <div hidden="">
                                  <input
                                    type="hidden"
                                    name="tags"
                                    value="3253895"
                                  />
                                </div>
                                <div id="mce-responses" class="clear">
                                  <div
                                    class="response"
                                    id="mce-error-response"
                                    style="display: none"
                                  ></div>
                                  <div
                                    class="response"
                                    id="mce-success-response"
                                    style="display: none"
                                  ></div>
                                </div>
                                <div
                                  aria-hidden="true"
                                  style="position: absolute; left: -5000px"
                                >
                                  <input
                                    type="text"
                                    name="b_8a6ba8cad090d6bca19b54352_fbaee145a1"
                                    tabindex="-1"
                                    value=""
                                  />
                                </div>
                                <div class="d-flex mt-10 justify-space-between">
                                  <input
                                    type="submit"
                                    name="subscribe"
                                    id="mc-embedded-subscribe"
                                    class="button button-subscribe"
                                    value="Subscribe"
                                  />

                                  <input
                                    name="close"
                                    id="mc-embedded-subscribe"
                                    class="button button-close"
                                    value="Close"
                                    @click="isActive.value = false"
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-dialog>
              </v-container> -->
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<style scoped lang="scss">
.faq-section-wrapper {
  width: 100%;
  border-top: 1px solid rgb(var(--v-theme-secondary));
  background-color: rgb(var(--v-theme-primary));
  padding: 140px 0 240px;
}

.text-bold {
  font-weight: 600 !important;
}

.faq-section {
  width: 100%;
  max-width: 964px;
  margin: 0 auto;
  padding: 0 32px;

  @media screen and (min-width: 576px) and (max-width: 1024px) {
    padding: 0 16px;
  }

  @media screen and (max-width: 576px) {
    padding: 0 16px;
  }
  // padding: 140px 270px 240px;
}

:deep(.v-expansion-panel) {
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: 0 !important;
}

.title {
  font-size: 60px;
  font-weight: 900;
  text-transform: uppercase;
  color: rgb(var(--v-theme-white));
  line-height: 1;
}

.title-btn-wrapper {
  margin-bottom: 80px;
}

.questions {
  margin-bottom: 180px;
}

.v-expansion-panel-title {
  font-size: 22px;
  font-weight: 800;
  line-height: 30.8px;
  text-transform: uppercase;
  color: rgb(var(--v-theme-white));
  padding: 30px 0 !important;
}

:deep(.v-expansion-panel-title__overlay) {
  background: transparent !important;
}

.v-expansion-panel-title {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

:deep(.v-expansion-panel-text__wrapper) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

:deep(.v-expansion-panel-text) {
  font-size: 15px;
  font-weight: 320;
  line-height: 24px;
  color: rgb(var(--v-theme-white));
}

:deep(.btn-sign-up) {
  padding: 16px 33px !important;
}

:deep(.v-btn__content) {
  span {
    font-size: 15px !important;
  }
}

.sign-up-text {
  font-size: 15px !important;
  font-weight: 320;
  line-height: 24px;
  color: rgb(var(--v-theme-soft-teal));
}

.title-not-sure {
  font-size: 105px;
  font-weight: 900;
  line-height: 84px;
  text-transform: uppercase;
  color: rgb(var(--v-theme-lime-green));
}

.not-sure-title-mobile {
  display: none !important;
}

.get-in-touch-wrapper-mobile {
  display: none !important;
}

.faq-list {
  padding-left: 20px !important;
}

.faq-list-item {
  padding: 10px 0 !important;
}

.sign-up-form {
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: rgb(var(--v-theme-primary));
  height: 100%;
  padding: 0px;
}

.button-subscribe {
  width: 80% !important;
  background-color: rgb(var(--v-theme-lime-green)) !important;
}

.button-close {
  width: 80% !important;
  background-color: rgb(var(--v-theme-primary)) !important;
}

#mc_embed_signup {
  background: #fff;
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;
  width: 600px;
}
#mc-embedded-subscribe-form input[type='checkbox'] {
  display: inline;
  width: auto;
  margin-right: 10px;
}
#mergeRow-gdpr {
  margin-top: 20px;
}
#mergeRow-gdpr fieldset label {
  font-weight: normal;
}
#mc-embedded-subscribe-form .mc_fieldset {
  border: none;
  min-height: 0px;
  padding-bottom: 0px;
}

@media screen and (min-width: 1439px) {
  .faq-section {
    max-width: 1440px !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 1024px) {
  .faq-section {
    padding: 60px 20px !important;
    max-width: 622px !important;
    width: 100% !important;
  }

  .not-sure-section {
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-size: 60px;
    font-weight: 900;
    line-height: 60px;
    text-align: left;
  }

  .title-btn-wrapper {
    flex-direction: column !important;
    align-items: flex-start !important;

    :deep(.v-btn) {
      // width: 100% !important;
      margin-top: 30px !important;
    }
  }

  .faq-section-title {
    text-align: left !important;
  }

  .title-not-sure {
    font-size: 105px;
    font-weight: 900;
    line-height: 84px;
    text-align: center;
  }

  .not-sure-wrapper {
    width: 100% !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .not-sure-title-desktop {
    display: none !important;
  }

  .not-sure-title-mobile {
    display: block !important;
    text-align: center !important;
    margin-bottom: 30px !important;
  }

  .sign-up-text {
    align-items: center !important;
  }

  .not-sure-sign-up-wrapper {
    width: 100% !important;
  }

  .not-sure-sign-up-content {
    width: 100% !important;
    min-width: 250px !important;
  }

  :deep(.v-expansion-panel-title__icon) {
    margin-left: 20px !important;
  }

  .get-in-touch-wrapper-mobile {
    display: block !important;
    margin-top: 340px !important;
  }
}

@media screen and (max-width: 576px) {
  .faq-section-wrapper {
    padding: 60px 0 !important;
  }

  .questions {
    margin-bottom: 140px !important;
  }

  .faq-section {
    padding: 60px 20px !important;
  }

  .not-sure-section {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-size: 42px !important;
  }

  .title-btn-wrapper {
    flex-direction: column !important;
    align-items: flex-start !important;

    :deep(.v-btn) {
      width: 100% !important;
      margin-top: 30px !important;
    }
  }

  .faq-section-title {
    text-align: left !important;
  }

  .title-not-sure {
    font-size: 60px !important;
    text-align: center !important;
    line-height: 60px !important;
  }

  .not-sure-wrapper {
    width: 100% !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .not-sure-title-desktop {
    display: none !important;
  }

  .not-sure-title-mobile {
    display: block !important;
    text-align: center !important;
    margin-bottom: 30px !important;
  }

  .sign-up-text {
    align-items: center !important;
  }

  .not-sure-sign-up-wrapper {
    width: 100% !important;
  }

  .not-sure-sign-up-content {
    width: 100% !important;
    min-width: 250px !important;
  }

  :deep(.v-btn) {
    width: 100% !important;
  }

  .get-in-touch-wrapper-mobile {
    display: block !important;
    margin-top: 340px !important;
  }
}
</style>
