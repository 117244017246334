<script setup lang="ts">
import experiencedFounders1 from '@/assets/images/svg/partners/experienced-founders-1.svg';
import experiencedFounders2 from '@/assets/images/svg/partners/experienced-founders-2.svg';
import experiencedFounders3 from '@/assets/images/svg/partners/experienced-founders-3.svg';
import experiencedFounders4 from '@/assets/images/svg/partners/experienced-founders-4.svg';

const props = defineProps<{
  height?: number;
  classNames?: string;
  direction?: 'left' | 'right';
}>();
</script>

<template>
  <div class="running-line mb-5">
    <Vue3Marquee
      :duration="30"
      :direction="props.direction === 'right' ? 'reverse' : 'normal'"
      :pause-on-hover="false"
    >
      <div class="icon-running-line-wrapper">
        <experiencedFounders1 class="trusted-partner-icon" />
      </div>
      <div class="icon-running-line-wrapper">
        <experiencedFounders2 class="trusted-partner-icon" />
      </div>
      <div class="icon-running-line-wrapper">
        <experiencedFounders3 class="trusted-partner-icon" />
      </div>
      <div class="icon-running-line-wrapper">
        <experiencedFounders4 class="trusted-partner-icon" />
      </div>
    </Vue3Marquee>
  </div>
</template>

<style scoped lang="scss">
.running-line {
  overflow: hidden;
  min-height: 51px;
  position: relative;
}

.icon-running-line-wrapper {
  margin-right: 60px;
}

.trusted-partner-icon {
  opacity: 0.6;
}
</style>
