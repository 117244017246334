<script setup lang="ts">
import corporates1 from '@/assets/images/svg/partners/corporates-1.svg';
import corporates2 from '@/assets/images/svg/partners/corporates-2.svg';
import corporates3 from '@/assets/images/svg/partners/corporates-3.svg';
import corporates4 from '@/assets/images/svg/partners/corporates-4.svg';
import corporates5 from '@/assets/images/svg/partners/corporates-5.svg';

const props = defineProps<{
  height?: number;
  classNames?: string;
  direction?: 'left' | 'right';
}>();
</script>

<template>
  <div class="running-line">
    <Vue3Marquee
      :duration="30"
      :direction="props.direction === 'right' ? 'reverse' : 'normal'"
      :pause-on-hover="false"
    >
      <div class="icon-running-line-wrapper">
        <corporates1 class="trusted-partner-icon" />
      </div>
      <div class="icon-running-line-wrapper">
        <corporates2 class="trusted-partner-icon" />
      </div>
      <div class="icon-running-line-wrapper">
        <corporates3 class="trusted-partner-icon" />
      </div>
      <div class="icon-running-line-wrapper">
        <corporates4 class="trusted-partner-icon" />
      </div>
      <div class="icon-running-line-wrapper">
        <corporates5 class="trusted-partner-icon" />
      </div>
    </Vue3Marquee>
  </div>
</template>

<style scoped lang="scss">
.running-line {
  overflow: hidden;
  min-height: 51px;
  position: relative;
}

.icon-running-line-wrapper {
  margin-right: 60px;
}

.trusted-partner-icon {
  opacity: 0.6;
}
</style>
