<script setup lang="ts">
const props = defineProps<{
  height?: number;
  classNames?: string;
  typeContent?: 'images' | 'text';
  withGradient?: boolean;
  direction?: 'left' | 'right';
}>();

const typeContent = computed(() => props.typeContent || 'text');
const containerRef = ref<HTMLElement | null>(null);
const contentRef = ref<HTMLElement | null>(null);
const tickerBlocks = ref<number>(3);
const contentWidth = ref(0);

const scrollDirection = computed(() => props.direction || 'left');

onMounted(() => {
  const calculateBlocks = () => {
    if (!containerRef.value || !contentRef.value) return;
    const containerWidth = containerRef.value.offsetWidth;
    contentWidth.value = contentRef.value.offsetWidth;
    const blocksNeeded = Math.ceil(containerWidth / contentWidth.value) + 1;
    tickerBlocks.value = Math.max(blocksNeeded, 2);
  };

  calculateBlocks();
  window.addEventListener('resize', calculateBlocks);

  onUnmounted(() => {
    window.removeEventListener('resize', calculateBlocks);
  });
});
</script>

<template>
  <div
    ref="containerRef"
    class="running-line w-100 position-relative"
    :class="[props.classNames, `scroll-${scrollDirection}`]"
    :style="{ height: `${props.height}px` }"
  >
    <template v-for="index in tickerBlocks" :key="index">
      <div
        :ref="index === 1 ? (el) => (contentRef = (el as HTMLElement | null)) : undefined"
        class="ticker-content h-100"
        :style="{
          width: 'max-content',
          justifyContent:
            typeContent === 'images' ? 'space-around !important' : 'flex-start',
          gap: typeContent === 'images' ? '0 !important' : '32px',
          left: `${(index - 1) * contentWidth}px`,
        }"
      >
        <slot></slot>
      </div>
    </template>
    <template v-if="props.withGradient">
      <div class="gradient-overlay gradient-left"></div>
      <div class="gradient-overlay gradient-right"></div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.running-line {
  overflow: hidden;
  min-height: 51px;
  position: relative;

  $duration: 30s;

  .ticker-content {
    position: absolute;
    top: 0;
    left: 0;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 32px;
    justify-content: flex-start;
    will-change: transform;
    backface-visibility: hidden;
    transform: translateZ(0);

    &:not(.scroll-right) {
      animation: tickerLeft $duration infinite linear forwards;
    }
  }

  &.scroll-right .ticker-content {
    animation: tickerRight $duration infinite linear forwards;
  }

  .gradient-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    pointer-events: none;
    z-index: 1;
  }

  .gradient-left {
    left: 0;
    background: linear-gradient(
      90deg,
      #02363e 13.57%,
      rgba(2, 54, 62, 0) 110.71%
    );
  }

  .gradient-right {
    right: 0;
    background: linear-gradient(
      270deg,
      #02363e 13.57%,
      rgba(2, 54, 62, 0) 110.71%
    );
  }
}

@keyframes tickerLeft {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

@keyframes tickerRight {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
