<script setup lang="ts">
import BtnPrimary from '@/components/ui/BtnPrimary.vue';
import { SectionIds } from '@/types/enums';

const config = useRuntimeConfig();

const handleApply = () => {
  // const link = config.public.membershipLink;
  const link =
    'https://wexelerate-membership.notion.site/1308e130c0a980a08eecc8c81833109c';
  window.open(link, '_blank');
};
</script>

<template>
  <section class="container home-section" :id="SectionIds.Home">
    <div class="home-title">
      <div class="ec-text-20xl text-white text-center">let’s Build</div>
      <div class="ec-text-20xl text-white text-center">
        the future <span class="text-lime-green font-italic">in</span>
      </div>
      <div
        class="ec-text-20xl text-lime-green text-center font-italic collaboration-line"
      >
        collabor<span>ation</span>
      </div>
    </div>
    <div class="join-us d-flex justify-center align-center">
      <div class="join-us-logos d-flex align-start">
        <img
          width="55"
          src="@/assets/images/co-owners/1.png"
          alt="co-owner-1"
        />
        <img
          width="55"
          src="@/assets/images/co-owners/2.png"
          alt="co-owner-2"
        />
        <img
          width="55"
          src="@/assets/images/co-owners/3.png"
          alt="co-owner-3"
        />
        <img
          width="55"
          src="@/assets/images/co-owners/logo.png"
          alt="co-owner-logo"
        />
      </div>
      <div class="join-us-text ml-5 d-flex flex-column align-start">
        <span class="text-soft-teal"
          >Join the co-owned accelerator powered</span
        >
        <span class="text-soft-teal"
          >by the
          <a
            href="https://wexelerate.com/"
            class="text-white cursor-pointer"
            target="_blank"
            >weXelerate</a
          >
          growth ecosystem</span
        >
      </div>
    </div>
    <div class="d-flex justify-center btn-join-us-block">
      <BtnPrimary title="Apply now to join!" @callback="handleApply" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.home-section {
  background-color: rgb(var(--v-theme-primary));
  background: linear-gradient(rgba(2, 54, 62, 0.96), rgba(2, 54, 62, 0.96)),
    url('@/assets/images/HomeBackground.png') no-repeat center center fixed;
  background-size: cover;
  min-height: calc(87vh - 90px);

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-title {
  padding-top: 100px;
}

.join-us {
  margin-top: 50px;

  img {
    margin-left: -19px;
  }
}

.btn-join-us-block {
  margin-top: 70px;
  margin-bottom: 93px;
}

.collaboration-line {
  word-break: break-word;
}

@media screen and (min-width: 1439px) {
  .home-section {
    min-height: 60vh;
  }
}

@media screen and (min-width: 576px) and (max-width: 1024px) {
  .home-section {
    padding: 79px 20px;
  }

  .home-title {
    padding-top: 0;

    div {
      font-size: 92px;
      font-weight: 900;
      line-height: 73.6px;
      text-align: center;
    }
  }

  .join-us {
    align-items: center;
    .join-us-logos {
    }

    .join-us-text {
    }
  }

  .btn-join-us-block {
    margin-top: 30px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 576px) {
  .home-section {
    padding: 79px 20px;
  }

  .home-title {
    padding-top: 0;

    div {
      font-size: 61px !important;
      text-align: left !important;
    }
  }

  .join-us {
    flex-direction: column !important;
    align-items: flex-start !important;

    .join-us-logos {
      margin-left: 17px;
      margin-bottom: 22px;
    }

    .join-us-text {
      margin-left: 0 !important;
    }
  }

  .btn-join-us-block {
    margin-top: 30px;
    margin-bottom: 0px;

    :deep(.v-btn) {
      width: 100%;
    }
  }
}
</style>
