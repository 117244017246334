<script setup lang="ts">
import BtnPrimary from '@/components/ui/BtnPrimary.vue';
import RunningLine from '@/components/common/RunningLine.vue';
import FirstPartnerLine from '@/components/ui/RunningLines/FirstPartnerLine.vue';
import SecondPartnerLine from '@/components/ui/RunningLines/SecondPartnerLine.vue';
import ThirdPartnerLine from '@/components/ui/RunningLines/ThirdPartnerLine.vue';
import FourthPartnerLine from '@/components/ui/RunningLines/FourthPartnerLine.vue';
import FifthPartnerLine from '@/components/ui/RunningLines/FifthPartnerLine.vue';

import { SectionIds } from '@/types/enums';

const sectionId = ref(SectionIds.OurPartners);

import { BenefitIds } from '@/types/enums';
const config = useRuntimeConfig();

const partners = [
  {
    name: 'CORPORATES',
    description: {
      text: 'Connect directly with corporates to launch pilot projects and gain early customers. We help you find the fast track through the corporate landscape.',
      benefits: [
        'Opportunities for pilot projects and test customers',
        'Find shortcuts through the corporate landscape',
      ],
    },
    // icons: [corporates1, corporates2, corporates3, corporates4, corporates5],
    benefitId: BenefitIds.Benefit2,
    runningLines: [FirstPartnerLine],
  },
  {
    name: 'INVESTORS',
    description: {
      text: 'Prepare your startup for successful fundraising.',
      benefits: [
        "Top investor access, including Austria's #1 VC, Speedinvest, and leading CVC, UNIQA Ventures.",
        'Guidance in securing funding through public and private channels.',
      ],
    },
    // icons: [investors1, investors2, investors3, investors4, investors5],
    benefitId: BenefitIds.Benefit4,
    runningLines: [SecondPartnerLine],
  },
  {
    name: 'PUBLIC BODIES',
    description: {
      text: 'Leverage Austria’s supportive environment for entrepreneurship with access to funding, networking, and resources. We connect you directly with key agencies and provide transparency on available programs',
      benefits: [
        'Direct introductions to funding and support agencies',
        'Clear overview of available programs and resources',
      ],
    },
    // icons: [covermentalBodies1, covermentalBodies2, covermentalBodies3],
    runningLines: [ThirdPartnerLine],
  },
  {
    name: 'experienced founders',
    description: {
      text: 'Get expert guidance to accelerate your growth.',
      benefits: [
        'Access to 50 topic experts from various industries.',
        'Several lighthouse mentors with successful funding and exit experiences.',
      ],
    },
    benefitId: BenefitIds.Benefit1,
    runningLines: [FourthPartnerLine, FifthPartnerLine],
  },
];

const handleApply = () => {
  // const link = config.public.membershipLink;
  const link =
    'https://wexelerate-membership.notion.site/1308e130c0a980a08eecc8c81833109c';

  window.open(link, '_blank');
};
</script>

<template>
  <div class="partners-container-wrapper">
    <section class="partners-container d-flex" :id="sectionId">
      <div class="partners-bg overflow-hidden">
        <img :src="'/images/PartnersBg.png'" alt="partners background" />
      </div>
      <div class="partners-content w-50 border-left-secondary">
        <div class="section-name pl-70 mb-10 padding-section">our partners</div>
        <div class="section-title pl-70 padding-section">Backed</div>
        <div class="section-title text-lime-green pl-70 padding-section">
          by industry
        </div>
        <div class="section-title text-lime-green pl-70 padding-section">
          leaders
        </div>
        <div class="section-description pl-70 padding-section">
          Recognized industry leaders are partnering with us to build an
          outstanding accelerator and empower founders.
        </div>

        <div class="section-btn pl-70 padding-section">
          <BtnPrimary
            title="Apply to join!"
            variant="outlined"
            @callback="handleApply"
          />
        </div>

        <div
          v-for="(partner, index) in partners"
          :key="partner.name"
          class="partners-partner"
          :id="partner.benefitId"
        >
          <div class="padding-section">
            <div class="section-partner-name">
              {{ `${index + 1}. ${partner.name}` }}
            </div>
            <div class="section-partner-description">
              {{ partner.description.text }}
            </div>
            <ul class="section-partner-benefits">
              <li
                v-for="benefit in partner.description.benefits"
                class="section-partner-benefit"
                :key="benefit"
              >
                {{ benefit }}
              </li>
            </ul>
          </div>
          <div class="section-partner-icons">
            <div class="gradient-overlay gradient-left"></div>
            <div class="gradient-overlay gradient-right"></div>
            <component
              v-for="runningLine in partner.runningLines"
              :key="runningLine.name"
              :is="runningLine"
              :height="55"
              typeContent="images"
              withGradient
            />
          </div>
        </div>

        <div class="client-reviews background-primary d-flex align-end">
          <div class="feedback-card-container w-50">
            <div class="feedback-card border-right-secondary">
              <RunningLine
                :height="51"
                classNames="feedback-card-running-line"
                :duration="40"
              >
                <div class="line-content-item">$131m raised</div>
                <div class="line-content-item">$131m raised</div>
                <div class="line-content-item">$131m raised</div>
                <div class="line-content-item">$131m raised</div>
                <div>{{ ' ' }}</div>
              </RunningLine>
              <div class="feedback-card-title">
                “<span class="text-primary-purple">We have great memories </span
                >from our time at weXelerate.”
              </div>
              <div class="feedback-card-description">
                A strong ecosystem is vital for founders, and wexelerate
                continues to be a driving force in fostering that growth.
              </div>
              <div class="feedback-card-person d-flex align-center pb-5">
                <div class="feedback-card-person-image">
                  <nuxt-img
                    width="60"
                    height="60"
                    src="/images/reviewers/reviewer-1.png"
                  />
                </div>
                <div
                  class="feedback-card-person-info d-flex flex-column justify-center ml-5"
                >
                  <div class="feedback-card-person-name">Kilian Kaminski</div>
                  <div class="feedback-card-person-job-title">
                    Founder of Refurbed
                  </div>
                </div>
              </div>
            </div>
            <div class="feedback-card border-right-secondary">
              <RunningLine :height="51" classNames="feedback-card-running-line">
                <div class="line-content-item">$91m raised</div>
                <div class="line-content-item">$91m raised</div>
                <div class="line-content-item">$91m raised</div>
                <div class="line-content-item">$91m raised</div>
                <div>{{ ' ' }}</div>
              </RunningLine>
              <div class="feedback-card-title">
                “Startups need
                <span class="text-primary-purple">more than just ideas</span>”
              </div>
              <div class="feedback-card-description">
                Startups need more than just ideas — they need a strong
                ecosystem, and wexelerate is building exactly that.
              </div>
              <div class="feedback-card-person d-flex pb-5">
                <div class="feedback-card-person-image">
                  <nuxt-img
                    width="60"
                    height="60"
                    src="/images/reviewers/reviewer-2.png"
                  />
                </div>
                <div
                  class="feedback-card-person-info d-flex flex-column justify-center ml-5"
                >
                  <div class="feedback-card-person-name">Domagoj Dolinsek</div>
                  <div class="feedback-card-person-job-title">
                    Founder of PlanRadar
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="feedback-card-container w-50">
            <div class="feedback-card">
              <RunningLine :height="51" classNames="feedback-card-running-line">
                <div class="line-content-item">€14m raised</div>
                <div class="line-content-item">€14m raised</div>
                <div class="line-content-item">€14m raised</div>
                <div class="line-content-item">€14m raised</div>
                <div>{{ ' ' }}</div>
              </RunningLine>
              <div class="feedback-card-title">
                "Programs like weXelerate
                <span class="text-primary-purple"
                  >are a golden opportunity for founders to fast track their
                  journey to success</span
                >.”
              </div>
              <div class="feedback-card-description">
                Startups thrive on the right connections, insights and exchange
                with other founders who have "been there, done that". It is
                crucial for success (and mental hygiene)
              </div>
              <div class="feedback-card-person d-flex pb-5">
                <div class="feedback-card-person-image">
                  <nuxt-img
                    width="60"
                    height="60"
                    src="/images/reviewers/reviewer-3.png"
                  />
                </div>
                <div
                  class="feedback-card-person-info d-flex flex-column justify-center ml-5"
                >
                  <div class="feedback-card-person-name">Janice Goodenough</div>
                  <div class="feedback-card-person-job-title">
                    Founder of Hydrogrid
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
.partner-icon {
  height: 55px;
  opacity: 0.6;
}

.partners-container-wrapper {
  background-color: rgb(var(--v-theme-primary));
}

.partners-container {
  background-color: rgb(var(--v-theme-primary));
}

.partners-content {
  padding: 160px 0 0;
}

.pl-70 {
  padding-left: 70px;
}

.partners-bg {
  width: 50%;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    // right: 0;
    // bottom: 0;
    height: 100%;
    width: 150%;
    // height: 100%;
    object-fit: cover;
  }
}

.section-title {
  font-size: 60px;
  font-weight: 900;
  color: rgb(var(--v-theme-white));
  line-height: 1;
  text-transform: uppercase;
}

.section-description {
  font-size: 18px;
  color: rgb(var(--v-theme-white));
  margin: 32px 0 56px;
  max-width: 580px;
}

.section-btn {
  margin-bottom: 32px;
}

.section-partner-name {
  position: sticky;
  top: 90px;
  font-size: 34px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  color: rgb(var(--v-theme-white));
  background-color: rgb(var(--v-theme-primary));
  padding: 40px 0;
  z-index: 100;
}

.section-partner-description {
  font-size: 18px;
  font-weight: 320;
  line-height: 32.4px;
  color: rgb(var(--v-theme-white));
  margin: 0 0 24px;
  max-width: 580px;
}

.section-partner-benefits {
  padding-left: 24px;
}

.section-partner-benefit {
  font-size: 18px;
  font-weight: 320;
  line-height: 32.4px;
  color: rgb(var(--v-theme-white));
  max-width: 580px;
}

.section-partner-icons {
  position: relative;
  margin: 40px 0 100px;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
  pointer-events: none;
  z-index: 100;
}

.gradient-left {
  left: 0;
  background: linear-gradient(
    90deg,
    #02363e 13.57%,
    rgba(2, 54, 62, 0) 110.71%
  );
}

.gradient-right {
  right: 0;
  background: linear-gradient(
    270deg,
    #02363e 13.57%,
    rgba(2, 54, 62, 0) 110.71%
  );
}

.second-line-icons {
  margin-top: 10px;
}

// Feedback Card
.feedback-card {
}

.feedback-card-running-line {
  background-color: rgb(var(--v-theme-lime-green));
}

.line-content-item {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 800;
  font-style: italic;
  line-height: 1;
  white-space: nowrap;
}

.feedback-card-title {
  font-size: 29px;
  font-weight: 900;
  line-height: 1;
  color: rgb(var(--v-theme-white));
  margin-top: 35px;
  margin-bottom: 20px;
  padding: 0 30px;
  text-transform: uppercase;
}

.feedback-card-description {
  font-size: 15px;
  font-weight: 320;
  line-height: 24px;
  color: rgb(var(--v-theme-white));
  margin-bottom: 67px;
  padding: 0 30px;
}

.feedback-card-person {
  padding: 0 30px;
}

.feedback-card-person-name {
  font-size: 17px;
  font-weight: 800;
  line-height: 23.8px;
  color: rgb(var(--v-theme-white));
}

.feedback-card-person-job-title {
  font-size: 15px;
  font-weight: 320;
  line-height: 24px;
  color: rgb(var(--v-theme-white));
}

.padding-section {
  padding: 0 70px !important;
}

@media screen and (min-width: 1439px) {
  .partners-container {
    max-width: 1440px !important;
    width: 100% !important;
    margin: 0 auto !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .section-partner-benefits {
    padding-left: 90px !important;
  }

  .client-reviews {
    flex-direction: column !important;
  }

  .feedback-card-container {
    width: 100% !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 1024px) {
  .partners-bg {
    width: 30% !important;
  }

  .partners-content {
    padding: 160px 0 0 !important;
    width: 70% !important;
  }

  .section-name {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .section-title {
    font-size: 60px;
    font-weight: 900;
    line-height: 60px;
    text-align: left;
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .section-description {
    padding-left: 70px !important;
    padding-right: 70px !important;
    min-width: 100% !important;
  }

  .section-btn {
    padding-left: 70px !important;
    padding-right: 70px !important;
    margin-bottom: 72px !important;
  }

  // .section-partner-name {
  //   padding-left: 50px !important;
  //   padding-right: 50px !important;
  // }

  .section-partner-description,
  .section-partner-benefits {
    // padding-left: 50px !important;
    // padding-right: 50px !important;
    font-size: 18px;
    font-weight: 320;
    line-height: 32.4px;
    text-align: left;
  }
}

@media screen and (max-width: 576px) {
  .partners-bg {
    display: none !important;
  }

  .partners-content {
    padding: 60px 0 !important;
    width: 100% !important;
  }

  // .section-name {
  //   padding-left: 0 !important;
  // }

  .section-title {
    font-size: 42px;
    font-weight: 800;
    line-height: 37.8px;
  }

  .section-partner-description,
  .section-partner-benefits {
    font-size: 15px;
    font-weight: 320;
    line-height: 24px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  // .section-description {
  //   padding-left: 0 !important;
  // }

  // .section-btn {
  //   padding-left: 0 !important;
  // }

  // .partners-partner {
  //   padding-left: 0 !important;
  // }

  .section-name {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .section-title {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .section-description {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .section-btn {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .section-partner-name {
    padding: 0 !important;
  }

  .padding-section {
    padding: 0 20px !important;
  }

  .client-reviews {
    flex-direction: column !important;
  }

  .feedback-card-container {
    width: 100% !important;
  }
}
</style>
