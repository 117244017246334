<script setup lang="ts">
import investors1 from '@/assets/images/svg/partners/investors-1.svg';
import investors2 from '@/assets/images/svg/partners/investors-2.svg';
import investors3 from '@/assets/images/svg/partners/investors-3.svg';
import investors4 from '@/assets/images/svg/partners/investors-4.svg';
import investors5 from '@/assets/images/svg/partners/investors-5.svg';

const props = defineProps<{
  height?: number;
  classNames?: string;
  withGradient?: boolean;
  direction?: 'left' | 'right';
}>();
</script>

<template>
  <div class="running-line">
    <Vue3Marquee
      :duration="30"
      :direction="props.direction === 'right' ? 'reverse' : 'normal'"
      :pause-on-hover="false"
    >
      <div class="icon-running-line-wrapper">
        <investors1 class="trusted-partner-icon" />
      </div>
      <div class="icon-running-line-wrapper">
        <investors2 class="trusted-partner-icon" />
      </div>
      <div class="icon-running-line-wrapper">
        <investors3 class="trusted-partner-icon" />
      </div>
      <div class="icon-running-line-wrapper">
        <investors4 class="trusted-partner-icon" />
      </div>
      <div class="icon-running-line-wrapper">
        <investors5 class="trusted-partner-icon" />
      </div>
    </Vue3Marquee>
  </div>
</template>

<style scoped lang="scss">
.running-line {
  overflow: hidden;
  min-height: 51px;
  position: relative;
}

.icon-running-line-wrapper {
  margin-right: 60px;
}

.trusted-partner-icon {
  opacity: 0.6;
}
</style>
